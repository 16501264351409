<template>
  <div>
    <Breadcrumb type="area" :data="linkData" v-if="linkData"/>
    <Banner :banner="bannerImage"/>
    <div class="my-5 hotelplusflight">
      <div class="container">
        <div class="row">
          <Sidebar :data="hotels" :packageType="categoryCode" :remarks="remarks" :includes="includes" />
          <div class="col-md-9 filter-cont dVacation-pack" v-if="isLoading || hotels.length === 0">
            <ContentLoading type="product" />
          </div>
          <div class="col-md-9 filter-cont dVacation-pack" v-else>
            <div class="holiday-hotel-details">
              <h2 v-if="isMaccabiAgency && cityCode==='BDV'">
                טיול מאורגן למונטנגרו
              </h2>
              <h2 v-if="isTargetAgency">
                מאורגן למונטנגרו
              </h2>
              <h2 v-else>
                 {{ categoryName }} {{ $t('product-page.for') }}{{ destinationName }} - {{ hotelName }}
              </h2>
            </div>
            <div class="d-flex retingbox" v-if="rating>2 && device==='desktop' && categoryCode !== 'Organize_tour_packages'">
              <b-form-rating
                class="ui rating"
                v-model="rating"
                variant="warning"
                locale="he"
                readonly
              ></b-form-rating>
            </div>
            <div class="clearfix"></div>
            <Breadcrumb type="flat" :stage="bookingStage" :data="linkData"/>
            <ReadMore :message="description" v-if="categoryCode === 'vacation_pack'"/>
            <ReadMore :message="description_t1" v-if="categoryCode === 'Organize_tour_packages'"/>
            <ReadMore :fixedText="description_t2_fixed" :message="description_t2" v-if="categoryCode === 'Organize_tour_packages'"/>

            <picker-ticket
              v-if="isSportPack && listTickets.length >0 && !isAppDealPage"
              :listTickets = "listTickets"
              :numOfPax = 'numOfPax'
              @change="changedTicket"
            />
            <picker-hotel
              v-if="isSportPack && !isAppDealPage"
              :defaultHotel="activeHotel"
              @change="changedHotel"
            />
            <div class="facilitie-list">
              <Facilitie v-for="(item, index) in product.hotels[idxHotel].fac" :key="index" :data="getFacData(item.code)"/>
            </div>

            <div class="clearfix"></div>
            <share-this type="product" v-if="hotelName" :shareContent="shareData"/>

            <!-- <maccabi-selection :flightItems="flightItems" v-if="isMaccabiAgency && availOptionDeadline && product" @update="updateProduct"/> -->

            <associated-products-list :productsList="associatedProducts" @update="updateProduct" v-if="isAssociated && associatedProducts && associatedProducts.length"/>

            <div class="share-button" @click="showShareThisModal" v-if="device==='mobile'">
              <svg xmlns="http://www.w3.org/2000/svg" class="share-icon" viewBox="0 0 512 512">
                <path d="M378,324a69.78,69.78,0,0,0-48.83,19.91L202,272.41a69.68,69.68,0,0,0,0-32.82l127.13-71.5A69.76,69.76,0,1,0,308.87,129l-130.13,73.2a70,70,0,1,0,0,107.56L308.87,383A70,70,0,1,0,378,324Z" fill="#28a745"></path></svg>
            </div>

            <Slider v-if="this.imageList.length" :slides="imageList" :destinationImage="destinationImage" :hotelName="hotelName"/>

            <div class="hotelplusflight_bannerarea">
              <div class="hotelplusflight_body">
                <h2>{{ hotelName }}</h2>
                <div class="d-flex retingbox" v-if="rating>2 && !isTargetAgency">
                  <b-form-rating
                    class="ui rating"
                    v-model="rating"
                    variant="warning"
                    locale="he"
                    readonly
                  ></b-form-rating>
                </div>

                <span class="d-inline-block"><a href="#"><i class="fas fa-map-marker-alt"></i>{{ categoryCode === 'Organize_tour_packages' ? product.hotels[idxHotel].cityName : product.hotels[idxHotel].address }}</a></span>
                <p class="d-block dated">
                  <i class="far fa-calendar-alt"></i>{{ betweenDateString }}
                </p>
                <p><i class="fas fa-user"></i>{{ personString }}</p>
                <!-- <p v-if="category.dealTypeCode === 'SportPack'"><i class="fas fa-ticket-alt"></i>{{ remark }}</p> -->
              </div>
            </div>

            <ReadMore :message="description_t1" v-if="categoryCode === 'Organize_tour_packages' && device === 'mobile'" :mobileShow="false" moreClass="bold-text" />
            <ReadMore :fixedText="description_t2_fixed" :message="description_t2" :mobileShow="true"
              v-if="categoryCode === 'Organize_tour_packages' && device === 'mobile'"/>
            <CollapseList :data="product" :categoryName="categoryCode" />

            <div class="mobail_part" v-if="false">
              <FlightDetail :flights="product.flights" />
            </div>

            <div v-if="!availableFlight && isAppDealPage">
              <section class="plaindetails-area">
                <div class="container">
                  <div class="plaindetails-box">
                    <h5>{{ $t("flight-only.unavailable-flight") }}</h5>
                  </div>
                </div>
              </section>
            </div>

            <div class="product-page-flight p-1" :class="flashing ? 'flashing' : ''" v-if="flightItems.length > 0 && idxFlight!== -1">
              <FlightOnlyItem
                :flight="flightItems[idxFlight]"
                :categoryName="categoryCode"
                type="product"
              />
            </div>

            <div class="select-flight d-flex"  v-if="!isOrganizeTour && flightItems.length>1 && !isOdysseySite && !isMaccabiAgency">
              <div class="selectflhtbtn m-auto" id="selectflhtbtn" v-b-toggle="`filter-content`">
                <img :src="`${imageDomain}/assets/img/flight.png`" alt="flight" />{{ $t('product-page.more-flight-option') }}
              </div>
            </div>
            <b-collapse class="filter-cont dVacation-pack" :id="`filter-content`" v-model="visibleMoreFlights" v-if="!isMaccabiAgency">
              <FlightOnlyItem
                v-for="(item, inx) in flightItems"
                :key="inx"
                :flight="item"
                :idx="inx"
                :selectIdx="idxFlight"
                :categoryName="categoryCode"
                type="product"
                @change="changeFlightIndex"
              />
            </b-collapse>

            <div class="chooseroom_area" ref="choose-room-area">
              <PriceArea
                :product="product"
                :hotelName="hotelName"
                :destinationCityName="destinationName"
                :includesText="includesText"
                :priceTicket="priceTicket"
                :beforePage="beforePage"
                @mesage="messageFromChild"
                @sendPrice="getProductPrice"
              />
            </div>

            <div class="price-composition-desktop" v-if="!isRemovePhoneButton">
              <RoomComposition :information="description_t3" :associated="isAssociated"/>
            </div>

            <!-- <div v-if="isAppDealPage" class="d-flex mt-3">
              <div class="m-auto">
                {{ $t("product-page.contact-us") }}:
                <span class="m-2"><a href="tel:035139924">03-5139924</a></span>
                <span class="m-2"><a href="mailto:sherut@flying.co.il">sherut@flying.co.il</a></span>
              </div>
            </div> -->

            <!-- <div v-if="isAppDealPage" class="d-flex mt-3">
              <div class="m-auto">
                {{ $t("product-page.rate-app-label") }}
                <input :value="$t('product-page.rate-app-button')" type="button" name="submit" id="btnSubmit" @click="handleRatingApp" />
              </div>
              <div v-if="availableAndroid">injected Android</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="!hideCondition"/>

    <b-modal id="share-this-modal" v-model="shareThisModal">
      <template #modal-header>
        <h4> {{ $t("share-this.share-modal-title") }} </h4>
      </template>
      <template #default>
        <ShareThis :shareContent="shareData"/>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          {{$t('booking.close')}}
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormRating, VBToggle, BCollapse,
  BButton,
} from 'bootstrap-vue';
import dayjs from 'dayjs';
import bannerImageDestination from '@/utils/bannerImageDestination';
import imageUrlMixin from '@/utils/imageUrlMixin';
import TemplateProductPage from './TemplateProductPage';

export default {
  name: 'ProductPageWeb',
  mixins: [bannerImageDestination, imageUrlMixin, TemplateProductPage],
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    Banner: () => import('@/components/productPage/ProductBanner'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    Sidebar: () => import('@/components/productPage/ProductSidebar'),
    ReadMore: () => import('@/components/productPage/atoms/Readmore'),
    Slider: () => import('@/components/productPage/ProductSlider'),
    FlightOnlyItem: () => import('@/components/searchResult/atoms/FlightOnlyItem'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    PriceArea: () => import('@/components/productPage/ProductPriceArea'),
    RoomComposition: () => import('@/components/productPage/RoomComposition'),
    CollapseList: () => import('@/components/productPage/CollapseList'),
    FlightDetail: () => import('@/components/productPage/FlightDetail'),
    Facilitie: () => import('@/components/productPage/atoms/Facilitie'),
    // Footer: () => import('@/components/HeaderFooter/Footer'),
    Footer: () => import('@/components/HeaderFooter/footer/FooterTheme0'),
    PickerTicket: () => import('@/components/productPage/PickerTicket'),
    PickerHotel: () => import('@/components/productPage/PickerHotel'),
    BFormRating,
    BCollapse,
    BButton,
    ShareThis: () => import('@/components/atoms/ShareThis'),
    // MaccabiSelection: () => import('./MaccabiSelection'),
    AssociatedProductsList: () => import('./AssociatedProductsSelection'),
  },
  data() {
    return {
      priceTicket: 0,
      beforePage: '',
      shareRate: null,
    };
  },
  computed: {
    ...mapGetters({
      homeLink: 'GET_HOME_LINK',
      packages: 'GET_PACKAGES',
      currentPage: 'GET_CURRENT_PAGE',
    }),
    isAssociated() {
      const { query } = this.$route;
      return Object.prototype.hasOwnProperty.call(query, 'associated');
    },
    hotelId() {
      return this.$route.query.laId;
    },
    packageId() {
      return this.query.packId;
    },
    eventId() {
      return this.query.event;
    },
    isSportPack() {
      return this.query.categoryId === 'sport_pack';
    },
    numOfPax() {
      return Number(this.query.adult) + Number(this.query.child);
    },
    listTickets() {
      const tickets = [];
      this.product.events.forEach((eventItem) => {
        eventItem.EventPrice.forEach((ticket) => {
          const eventName = eventItem.EventName.find((item) => item.LA_ID === ticket.LA_ID);
          tickets.push({
            ...ticket,
            ...eventName,
            labelClass: this.product?.translations?.eventTicketClass?.[ticket.room_class] ?? ticket.room_class,
            packId: this.product.packId,
            supplier: eventItem.Supplier,
          });
        });
      });
      return tickets.sort((a, b) => a.price - b.price);
    },
    shareData() {
      return {
        category: this.product.category.dealTypeCode,
        dest: this.destinationName,
        hotel: this.hotelName,
        image: this.imageList[0],
        duration: this.tripDuration,
        link: `${window.location.pathname}${window.location.search}`,
        // country: this.product.translations.flightDestinationCountry?.[this.product.destination_1]?.[this.lang] || '',
        country: this.product.translations.cityCountry?.[this.product?.hotels[this.idxHotel]?.city]?.[this.lang] || '',
        rate: this.shareRate || '',
      };
    },
    isVercel() {
      return window.location.href.includes('vercel') || window.location.href.includes('localhost');
    },
  },
  watch: {
    lang() {
      this.updateLabelWithLang();

      this.personString = this.getPersonString(
        Number(this.$route.query.adult),
        Number(this.$route.query.child),
        Number(this.$route.query.infant),
      );
      this.getBetweenDateString();
      this.getItineraryDetail();
    },
  },
  async created() {
    this.beforePage = this.currentPage;
    this.$store.commit('SET_CURRENT_PAGE', 'product');
    document.body.classList.add('productPage');
    this.activeHotel = this.$route.query.laId;

    window.sessionStorage.setItem('dealType', this.query.categoryId);

    if (this.subjectCategory.length === 0) {
      this.$store.dispatch('FETCH_SUBJECT_CATEGORY');
    }

    window.sessionStorage.setItem('marketerURL', this.$route.fullPath);
    window.sessionStorage.setItem('BookingDataID', '');
    const isBlankData = await this.fetchData(true);
    if (!isBlankData) return;

    this.analysisProductData();

    this.updateLabelWithLang();

    /**
     * If user start a booking from first, remove stored maccabi data.
     */
    if (this.isMaccabiAgency) {
      window.sessionStorage.removeItem('maccabi-info');
    }
  },
  mounted() {
    this.personString = this.getPersonString(
      Number(this.$route.query.adult),
      Number(this.$route.query.child),
      Number(this.$route.query.infant),
    );
    if (this.isOdysseySite) {
      this.$store.commit('SET_SELECTED_ODYSSEY_AGENT_CODE', this.query.odyAgentCode || this.agentContent.odyAgentCode);
    }
    if (this.isAssociated && this.hotelId) {
      this.$store.dispatch('FETCH_ASSOCIATED_PRODUCTS', {
        hotelId: this.hotelId,
        category: this.category.code,
      });
    }
  },
  methods: {
    async fetchData(isFirst) {
      this.query = this.$route.query;
      // this.discounted = Number(this.query.discount);
      // !this.query.flights |||| !this.query.roomType || !this.query.roomClass
      if (!this.query.packId || !this.query.laId || !this.query.categoryId || !this.query.dateFrom || !this.query.adult) {
        this.$bvModal.msgBoxOk(
          this.$t('search-result.dont-remove-query'),
          {
            title: this.$t('product-page.expire-title'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return false;
      }

      // if (this.isSportPack) { // temp
      //   const searchQuery = JSON.parse(sessionStorage.getItem('search-query'));
      //   await this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', searchQuery);
      // }
      await this.$store.dispatch('FETCH_PRODUCT', this.query);

      // this.$root.$emit('send-share-data', this.shareData);

      await this.fetchBannerImageByDestination();

      if (this.product?.hotels === undefined || this.product?.hotels?.length === 0) {
        if (this.isAssociated && this.associatedProducts && this.associatedProducts.length) {
          // message = this.$t('product-page.no-seats');
          // redirectLink = this.associatedProducts[0].productPageUrl;
          this.$router.push(this.associatedProducts[0].productPageUrl);
          this.$router.go();
          return false;
        }

        this.$bvModal
          .msgBoxOk(this.$t('product-page.expire-message'), {
            title: this.$t('product-page.expire-title'),
            okVariant: 'danger',
            okTitle: this.$t('product-page.go-back'),
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            bodyClass: 'text-right',
            centered: true,
          })
          .then(() => {
            this.$router.push(this.homeLink);
          })
          .catch(() => {
            // An error occurred
          });
        return false;
      }

      if (!this.isAssociated && document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
      if (this.isAssociated && !isFirst) document.querySelector('.st-content').scrollTo(0, this.$refs['choose-room-area']?.offsetTop || 0);
      return true;
    },
    getItineraryDetail() {
      if (this.categoryCode === 'Organize_tour_packages') {
        this.description_t1 = this.product.Itinerary?.include;
        if (this.product?.Itinerary?.itineraryDetail) {
          this.description_t2_fixed = this.product.Itinerary.itineraryDetail
            .filter((item, index) => index < 2)
            .map((item, index) => `<h3><img src="${this.imageDomain}/assets/img/icon_ster.png" alt="icon-star" width="26" height="26"> ${this.$t('product-page.day')} ${index + 1}. ${item.header}</h3>${item.text}`).join('<br>');
          this.description_t2 = this.product.Itinerary.itineraryDetail.filter((item, index) => index > 1)
            .map((item, index) => `<h3><img src="${this.imageDomain}/assets/img/icon_ster.png" alt="icon-star" width="26" height="26"> ${this.$t('product-page.day')} ${index + 3}. ${item.header}</h3>${item.text}`)
            .join('<br>');
        }
        this.description_t3 = this.product.notesTranslation[this.lang];
      }
    },
    getBetweenDateString() {
      if (this.product && this.product.flights) {
        const checkIn = dayjs(this.product.flights[0]?.FlightDetail[0]?.FL_Date).add(Number(this.product.hotel_shift_1), 'day'),
          checkOut = checkIn.add(Number(this.product.duration_1), 'day');
        if (this.lang === 'he') {
          this.tripDuration = `${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} - ${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} (${this.hotels[this.idxHotel]?.duration} ${this.$t('search-result.night')})`;
          this.betweenDateString = this.tripDuration;
        } else {
          this.tripDuration = `${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} -
            ${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} (${this.hotels[this.idxHotel]?.duration} ${this.$t('search-result.night')})`;
          this.betweenDateString = this.tripDuration;
        }
      }
    },
    updateLabelWithLang() {
      if (this.product && this.product.translations) {
        this.hotelName = this.product?.translations?.hotelName?.[this.activeHotel]?.en ?? this.product?.hotels?.[this.idxHotel]?.hotelName ?? '';
        this.categoryName = this.product?.category?.name.en;
        this.destinationName = this.product?.hotels[this.idxHotel]?.cityName;
        const basisCode = this.hotels[this.idxHotel]?.basisName;
        this.basisName = this.product.translations?.basisName?.[basisCode]?.[this.lang] ?? basisCode;

        const hotelCityCode = this.product?.hotels[this.idxHotel]?.city;
        this.categoryName = this.product.category?.name[this.lang] || this.product.category?.name?.en;
        this.categoryName = this.categoryName.replace('חבילות נופש', 'חבילת נופש');
        this.destinationName = this.product.translations?.city[hotelCityCode][this.lang] || this.product?.hotels[this.idxHotel]?.cityName;

        const body = this.categoryCode === 'Organize_tour_packages' ? {
          dest: Object.keys(this.product.translations.city)[0],
          yearMonth: this.query.dateFrom.substr(0, 7),
          subjectIds: this.product ? this.subjectCategory.find((sub) => (sub.subjectCodes.includes(String(this.product.subj_id)))).id : '',
          categoryId: this.query.categoryId,
          adult: this.query.adult,
          child: this.query.child,
          infant: this.query.infant,
        } : {
          dest: Object.keys(this.product.translations.city)[0],
          fromDate: this.query.dateFrom,
          toDate: dayjs(this.query.dateFrom).add(Number(this.product.duration_1), 'day').format('YYYY-MM-DD'),
          categoryId: this.query.categoryId,
          adult: this.query.adult,
          child: this.query.child,
          infant: this.query.infant,
        };

        if (this.query.utm_source) body.utm_source = this.query.utm_source;
        if (this.isLanding) body.utm_source = this.landingInfo.utm_source;
        if (this.marketerId) body.marketerId = this.marketerId;
        if (this.isFcAgentMarketerMode) body['fc-agent-mode'] = '';

        const searchQuery = this.query.categoryId === 'sport_pack'
          ? `/search-result-sportpack?${new URLSearchParams(JSON.parse(window.sessionStorage.getItem('search-query'))).toString()}`
          : `/search-result?${new URLSearchParams(body).toString()}`;

        this.linkData = {
          text: this.product.category?.name[this.lang] || this.product.category?.name?.en,
          dest: this.product.translations?.city[hotelCityCode][this.lang] || this.product?.hotels[this.idxHotel]?.cityName,
          stage: this.$t(this.stageLabel[this.bookingStage]),
          searchQuery,
        };
        this.cityCode = this.product.hotels[0].city || this.product.destination_1;
        this.remarks = this.product?.productContent?.text || this.product.remarkTranslation[this.lang] || this.product.remark;
        this.description_t3 = this.product?.productContent?.notes || this.product.notesTranslation[this.lang];
      }
    },
    changedTicket(pTicket) {
      if (pTicket.numOfTickets > 0) {
        const ticket = this.listTickets[pTicket.index];
        this.priceTicket = ticket.price * pTicket.numOfTickets;
        this.product.eventTicketReservation = {
          eventId: ticket.LA_ID,
          eventCode: ticket.Htl_Code,
          eventSupplier: ticket.supplier,
          eventName: ticket.Htl_Name,
          eventCityCode: ticket.Htl_City,
          eventFromDate: ticket.dat,
          eventToDate: ticket.dat,
          tiketClassCode: ticket.room_class,
          ticketTypeCode: ticket.room_type,
          price: ticket.price,
          currency: ticket.cc,
          numOfTickets: pTicket.numOfTickets,
        };
        this.$store.commit('SET_PRODUCT', this.product);
      }
    },
    async changedHotel(pHotel) {
      if (pHotel.hotelId !== this.activeHotel) {
        this.activeHotel = pHotel.hotelId;
        await this.$store.dispatch('FETCH_PRODUCT', {
          ...this.query,
          laId: pHotel.hotelId,
        });
        this.analysisProductData();
      }
    },
    getProductPrice(price) {
      this.shareRate = price;
      this.$root.$emit('send-share-data', this.shareData);
    },
    updateProduct() {
      this.fetchData();
    },
    getFacData(code) {
      return this.product.translations.fac[code][this.lang];
    },
  },
};

</script>

<style>
.productPage .btnDomestic {display:none !important;}
.modal {
  background-color: transparent;
}
.b-rating .b-rating-star,
.b-rating .b-rating-value {
  padding: 0 0.1em;
}
.modal::before {
  height: auto !important;
}
.custom-control-input {
  left: -20px !important;
}
@media (min-width: 576px) {
  .modal.show .modal-dialog {
      min-width: 500px;
  }
}
</style>

<style scoped>
.hotelplusflight {
  margin-top: 1em !important;
}
.retingbox .rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
  padding: 0;
  width: auto;
}
.clearfix {
  clear: both;
}
.hotelplusflight_body,
.mobail_part {
  display: none;
}

.select-flight .selectflhtbtn{
  padding: 10px 70px;
}
.share-button {
  transform: translate(-40px, 60px);
}
.share-button .share-icon {
  width: 45px;
  height: 45px;
}

@media (max-width: 479px) {
  .holiday-hotel-details,
  .facilitie-list,
  .price-composition-desktop {
    display: none;
  }
  .hotelplusflight_body,
  .mobail_part {
    display: block;
  }
  .product-page-flight .filterItem {
    margin: 0;
  }
  .dVacation-pack {
    padding: 3px;
  }
  .select-flight .selectflhtbtn{
    padding: 10px 15px;
  }
  .share-button {
    transform: translate(-10px, 10px);
  }
  .share-button .share-icon {
    width: 30px;
    height: 30px;
  }
}
</style>
